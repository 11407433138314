var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-spacer'),_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_c('v-text-field',{attrs:{"placeholder":"Search..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users.records,"loading":_vm.isLoading,"search":_vm.search,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm._f("getFormattedDate")(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm._f("getFormattedDate")(item.updatedAt)))])]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_c('router-link',{attrs:{"to":'/admin/user/' + item.id + '/edit'}},[_vm._v(_vm._s(item.firstName))])],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.role == 'admin' ? 'primary' : 'seconadry'}},[_vm._v(_vm._s(item.role))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }