<template>
  <div>
    <v-spacer></v-spacer>
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field v-model="search" placeholder="Search..."></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users.records"
        :loading="isLoading"
        :search="search"
        class="elevation-1"
        transition="scale-transition"
      >
        <template v-slot:item.createdAt="{ item }">
          <h5 color="primary">{{ item.createdAt | getFormattedDate }}</h5>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <h5 color="primary">{{ item.updatedAt | getFormattedDate }}</h5>
        </template>
        <template v-slot:item.firstName="{ item }">
          <h5 color="primary">
            <router-link :to="'/admin/user/' + item.id + '/edit'">{{
              item.firstName
            }}</router-link>
          </h5>
        </template>
        <template v-slot:item.role="{ item }">
          <v-chip
            label
            :color="item.role == 'admin' ? 'primary' : 'seconadry'"
            >{{ item.role }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" small class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon color="error" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

// import { mapState } from 'vuex'
export default {
  data() {
    return {
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Created Date", value: "createdAt" },
        { text: "Updated Date", value: "updatedAt" },
        { text: "Actions", align: "right", sortable: false, value: "actions" },
      ],
      search: "",
      users: [],
      totalRecords: 0,
      isLoading: false,
    };
  },

  apollo: {
    users: {
      query: gql`
        query Users($limit: Int!, $skip: Int!, $query: JSON!) {
          users(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              firstName
              lastName
              email
              role
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    editItem(item) {
      this.$router.push(`/admin/user/${item.id}/edit`);
    },

    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c79477",
        cancelButtonColor: "#707077",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation($id: ID!) {
                  deleteUser(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal({
                title: "Success",
                text: "User was successfully deleted",
                icon: "success",
                confirmButtonColor: '#c79477',
              });
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {
            console.log(`Login Failed ${JSON.stringify(e)}`);
            self.errorMsg = e.message;
            self.$swal({
              title: "Error",
              text: "Login failed",
              icon: "error",
              confirmButtonColor: '#c79477',
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
